import React, { useState } from 'react'
import tw, { styled } from 'twin.macro'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import ReCAPTCHA from 'react-google-recaptcha'
import Container from '../../components/Container'
import Button from '../../components/Button'

const Label = styled.label`
  ${tw`text-sm pb-3 text-purple pr-2 w-full tablet:pb-5 tablet:pr-0`}
`
const Input = styled.input`
  ${tw`box-border w-full text-black bg-white border`}
`
const Form = styled.form`
  ${tw`justify-between items-start mt-3 tablet:flex-col tablet:items-start tablet:h-auto`}
`
const ContactForm = () => {
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [message, setMessage] = useState('')
  const [honeyPot, setHoneyPot] = useState('')
  const [captcha, setCaptcha] = useState(false)

  const [buttonText, setButtonText] = useState('Submit')

  const [errors, setErrors] = useState('')

  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [showFailureMessage, setShowFailureMessage] = useState(false)

  const handleValidation = () => {
    const tempErrors = {}
    let isValid = true
    const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i

    if (firstName.length <= 0) {
      tempErrors.firstName = true
      isValid = false
    }
    if (email.length <= 0 || regex.test(email) === false) {
      tempErrors.email = true
      isValid = false
    }
    if (lastName.length <= 0) {
      tempErrors.lastName = true
      isValid = false
    }
    if (message.length <= 0) {
      tempErrors.message = true
      isValid = false
    }
    if (captcha === false) {
      tempErrors.captcha = true
      isValid = false
    }
    if (honeyPot.length > 0) {
      tempErrors.honeyPot = true
      isValid = false
    }

    setErrors({ ...tempErrors })
    console.log('errors', errors)
    return isValid
  }

  const handleOnSubmit = async e => {
    // We don't want you to submit to the browser
    e.preventDefault()

    const isValidForm = handleValidation()
    setShowFailureMessage(false)
    setShowSuccessMessage(false)

    if (isValidForm) {
      setButtonText('Sending...')
      const res = await axios({
        method: 'POST',
        url: 'https://formspree.io/f/mknewejy',
        data: {
          first_name: firstName,
          last_name: lastName,
          email,
          message,
        },
      })
      const { error } = await res
      if (error) {
        console.log(error)
        setShowSuccessMessage(false)
        setShowFailureMessage(true)
        setButtonText('Send')
        return
      }
      setShowSuccessMessage(true)
      setShowFailureMessage(false)
      setButtonText('Submit')

      setFirstName('')
      setLastName('')
      setEmail('')
      setMessage('')
      return
    }

    setShowFailureMessage(true)
  }

  const onChange = value => {
    console.log('value', value)
    if (value) {
      setCaptcha(true)
    } else {
      setCaptcha(false)
    }
  }

  return (
    <Container variant="wide" tw="py-10 tablet:py-5">
      <Helmet>
        <script src="https://www.google.com/recaptcha/api.js" />
      </Helmet>
      <div tw="text-left">
        {showSuccessMessage && <p tw="text-green font-semibold text-sm">Thank you! You've succesfully sent us an email</p>}
        {showFailureMessage && <p tw="text-red-500 text-sm font-semibold">Oops! Something went wrong, please try again.</p>}
      </div>
      <Form method="post" onSubmit={handleOnSubmit}>
        <div tw="flex space-x-3 tablet:flex-col tablet:space-x-0">
          <Label htmlFor="first_name">
            <strong>First Name</strong>
            <Input
              name="first_name"
              value={firstName}
              onChange={e => {
                setFirstName(e.target.value)
              }}
            />
            {errors?.firstName && <p tw="text-red-500 font-bold">Please add your first name</p>}
          </Label>
          <Label htmlFor="last_name">
            <strong>Last Name</strong>
            <Input
              name="last_name"
              value={lastName}
              onChange={e => {
                setLastName(e.target.value)
              }}
            />
            {errors?.lastName && <p tw="text-red-500 font-bold">Please add your last name</p>}
          </Label>
          <Label htmlFor="email">
            <strong>Email</strong>
            <Input
              name="email"
              value={email}
              onChange={e => {
                setEmail(e.target.value)
              }}
            />
            {errors?.email && <p tw="text-red-500 font-bold">Email cannot be empty or invalid.</p>}
          </Label>
        </div>
        <Label htmlFor="message">
          <strong>Message</strong>
          <textarea
            tw="h-[300px] box-border w-full rounded-xl text-black bg-white border p-2"
            value={message}
            onChange={e => {
              setMessage(e.target.value)
            }}
          />
          {errors?.message && <p tw="text-red-500 font-bold">Please add a brief message</p>}
        </Label>
        {/* HONEYPOT FIELD */}
        <Label htmlFor="otherEmail" tw="hidden">
          <strong>Email</strong>
          <Input
            name="otherEmail"
            placeholder="NOT A REAL FIELD"
            value={honeyPot}
            onChange={e => {
              setHoneyPot(e.target.value)
            }}
          />
        </Label>
        <div tw="tablet:pb-3">
          {errors?.captcha && <p tw="text-red-500 font-bold py-0">Please complete ReCaptcha</p>}
          <ReCAPTCHA sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY} onChange={onChange} />
        </div>
        <Button tw="mt-5 tablet:my-auto" variant="secondary" type="submit">
          {buttonText}
        </Button>
      </Form>
    </Container>
  )
}

ContactForm.propTypes = {
  //
}

export default ContactForm
